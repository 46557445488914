var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('ValidationObserver',{ref:"validation-observer"},[_c('b-row',[_c('b-col',{staticClass:"px-1",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Название [RU]","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название [RU]","label-for":"name-ru"}},[_c('b-form-input',{attrs:{"id":"name-ru","size":"md","placeholder":"Введите"},model:{value:(_vm.name.ru),callback:function ($$v) {_vm.$set(_vm.name, "ru", $$v)},expression:"name.ru"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('b-col',{staticClass:"px-1",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Название [UZ]","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Название [UZ]","label-for":"name-uz"}},[_c('b-form-input',{attrs:{"id":"name-uz","size":"md","placeholder":"Введите"},model:{value:(_vm.name.uz),callback:function ($$v) {_vm.$set(_vm.name, "uz", $$v)},expression:"name.uz"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1),_c('b-row',[_c('b-col',{staticClass:"px-1",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Изображение","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Изображение"}},[_c('VueFileAgent',{ref:"vueFileAgent",attrs:{"deletable":true,"meta":true,"accept":'image/*,.zip',"maxSize":'5MB',"maxFiles":1,"helpText":'Выберите изображение',"errorText":{
                                    type: 'Неверный тип файла. Разрешены только изображения или zip',
                                    size: 'Размер файла не должен превышать 10 МБ.',
                                }},on:{"select":function($event){return _vm.filesSelected($event)},"beforedelete":function($event){return _vm.onBeforeDelete($event)},"delete":function($event){return _vm.fileDeleted($event)}},model:{value:(_vm.fileRecords),callback:function ($$v) {_vm.fileRecords=$$v},expression:"fileRecords"}})],1),(errors)?_c('p',{staticClass:"validation__red"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-button',{staticClass:"btn-success float-right mt-2 mr-1",attrs:{"disabled":_vm.isSaving},on:{"click":_vm.saveCategory}},[_vm._v(" Сохранить ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }